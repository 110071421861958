import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import ProductСard from 'components/ProductCard';
import ControlledCarousel from 'components/ControlledCarousel';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { flattenDepth, isArray } from 'lodash';
import Button from 'components/common/Button';
import { responsiveObj, responsiveObjArticle } from './constants';
import { IRecommendedProductsProps } from './model';
import './RecommendedProducts.scss';

const RecommendedProducts: FC<IRecommendedProductsProps> = ({
  bgColor = 'white',
  productLinks,
  products,
  title,
  isFamily,
  isArticlePage = false,
  ariaAllProducts,
  linkAllProducts,
  hideFakeLink,
}) => {
  const responsiveCfg = isArticlePage ? responsiveObjArticle : responsiveObj;
  const { isMobile, isTablet } = useScreenRecognition();
  const classes = classnames('gs-symptom-section__panel-block', 'recommended-products', {
    [`${bgColor}-default-bg`]: bgColor,
  });
  const unwrappedProducts = useMemo(
    () =>
      flattenDepth(
        productLinks?.map(
          ({ properties: { link, customFakeLink, scrollTarget, hideVariant, cardTheme } }) =>
            link
              .map((_, index) => {
                const prodProps = (prodUrl) => {
                  const productItem = products?.nodes?.filter((item) => item.link === prodUrl)[0];

                  return {
                    ...productItem,
                    hideVariant,
                    cartFakeLink: customFakeLink || productItem?.cartFakeLink,
                    scrollTarget,
                    cardTheme,
                  };
                };
                const getProdById = (id) => ({
                  isPare: id !== 0,
                  ...prodProps(link[id].url),
                });

                if (isMobile || isTablet) {
                  if (link[index + 1]) {
                    return [getProdById(index), getProdById(index + 1)];
                  }
                  if (link[index - 1]) {
                    return null;
                  }

                  return getProdById(0);
                }

                return getProdById(index);
              })
              .filter((_) => _)
        ) || [],
        1
      ),
    [products, isMobile, isTablet]
  );

  return productLinks?.length ? (
    <div className={classes}>
      {title ? <h2 className="recommended-products__title">{title}</h2> : null}
      <ControlledCarousel
        showDots
        dotsOutside
        showNavigators
        classes="recommended-products-carousel"
        responsiveObj={responsiveCfg}
        navigatorsOutside
      >
        {unwrappedProducts.map((productItem) => {
          const doubleProduct = [
            productItem && <ProductСard key={Math.random()} {...{ ...productItem[0], isFamily }} />,
            productItem && <ProductСard key={Math.random()} {...{ ...productItem[1], isFamily }} />,
          ];

          return isArray(productItem) ? (
            <div key={Math.random()} className="double-recommended-product">
              {doubleProduct}
            </div>
          ) : productItem ? (
            <ProductСard
              key={Math.random()}
              {...{ ...productItem, isFamily }}
              hideFakeLink={hideFakeLink}
            />
          ) : null;
        })}
      </ControlledCarousel>
      {linkAllProducts?.length ? (
        <div className="related-products__more-line">
          <Button
            ariaLabel={ariaAllProducts}
            classes="related-products__more"
            to={linkAllProducts[0].url}
            variant="secondary"
          >
            {linkAllProducts[0].name}
          </Button>
        </div>
      ) : null}
    </div>
  ) : null;
};
export default RecommendedProducts;
